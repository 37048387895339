<template>
    <div v-if="$store.state.appLoading" class="h-screen bg-primary">
        <div class="flex items-center justify-center text-center w-full h-full">
            <div class="p-4 flex flex-col justify-center">
                <img src="@/assets/overlay-loader.gif" class="animate-bounce w-72 m-auto" />
                <div
                    class="bg-clip-text text-white bg-gradient-to-r from-primary to-yellow font-extrabold text-3xl md:text-5xl mt-5 animate-pulse leading-none whitespace-nowrap"
                >
                    Please wait a moment
                </div>
            </div>
        </div>
    </div>
    <transition name="moveUp" v-else>
        <div>
            <div
                v-show="$store.state.overlayLoading"
                class="fixed top-0 left-0 right-0 bottom-0 w-full h-screen bg-black bg-opacity-80 z-999 flex items-center"
            >
                <div class="w-screen">
                    <img src="@/assets/overlay-loader.gif" class="animate-bounce object-contain mx-auto w-1/6" />
                    <div class="text-white text-lg font-semibold mt-2 text-center">Please wait a moment</div>
                </div>
            </div>
            
            <router-view></router-view>
            <div class="hidden md:block md:fixed md:z-90 md:bottom-7 md:right-7 md:p-4" v-if="$route.name != 'InvoiceDownload'">
                <a href="https://wa.me/message/LAFEDFCR4JY4L1" target="_blank" title="Contact us"
                class="h-16 w-16 bg-green-600 p-3 rounded-full drop-shadow-lg flex justify-center items-center text-white text-4xl">
                <font-awesome-icon icon="fa-brands fa-whatsapp"/>
                </a>
            </div>
        </div>
    </transition>
</template>

<script>
    export default {
        name: 'App',

        data() {
            return {
                screenWidth: 0
            };
        },

        created() {
            this.screenWidth = window.innerWidth;
        },

        mounted() {
            window.onresize = () => (this.screenWidth = window.innerWidth);
        },

        computed: {
            shouldHideContent() {
                return false;
                // return this.screenWidth < 768;
            }
        }
    };
</script>

<style>
    /* moveUp */
    .moveUp-enter-active {
        animation: fadeIn 0.5s ease-in;
    }
    @keyframes fadeIn {
        0% {
            opacity: 0;
        }
        50% {
            opacity: 0.5;
        }
        100% {
            opacity: 1;
        }
    }

    .moveUp-leave-active {
        animation: moveUp 0.1s ease-in;
    }
    @keyframes moveUp {
        0% {
            transform: translateY(0);
        }
        100% {
            transform: translateX(-100px);
        }
    }

    .opa-enter,
    .opa-leave-to {
        opacity: 0;
    }

    .opa-leave,
    .opa-enter-to {
        opacity: 1;
    }

    .opa-enter-active,
    .opa-leave-active {
        transition: opacity 300ms;
    }

    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.3s ease-out;
    }

    .fade-enter-from,
    .fade-leave-to {
        opacity: 0;
    }

    .slide-enter-active,
    .slide-leave-active {
        transition: all 0.75s ease-out;
    }
    .slide-enter-to {
        position: absolute;
        right: 0;
    }
    .slide-enter-from {
        position: absolute;
        right: -100%;
    }
    .slide-leave-to {
        position: absolute;
        left: -100%;
    }
    .slide-leave-from {
        position: absolute;
        left: 0;
    }

    .scale-enter-active,
    .scale-leave-active {
        transition: all 0.2s ease;
    }
    .scale-enter-from,
    .scale-leave-to {
        opacity: 0;
        transform: scale(0.9);
    }

    .scale-slide-enter-active,
    .scale-slide-leave-active {
        position: absolute;
        transition: all 0.85s ease;
    }
    .scale-slide-enter-from {
        left: -100%;
    }
    .scale-slide-enter-to {
        left: 0%;
    }
    .scale-slide-leave-from {
        transform: scale(1);
    }
    .scale-slide-leave-to {
        transform: scale(0.8);
    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type='number'] {
        -moz-appearance: textfield;
    }
</style>
