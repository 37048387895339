import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/tailwind.css";
import * as VueGoogleMaps from "vue2-google-maps";
import "leaflet/dist/leaflet.css";
import VueGeolocation from "vue-browser-geolocation";

import "@/plugins/font-awesome.js";
import "@/plugins/axios.js";
import "@/plugins/signature.js";
import "@/plugins/moment.js";
import "@/plugins/qr-code.js";
import "@/plugins/countdown.js";
import "@/plugins/otp-input.js";
import "@/plugins/calendar.js";
import "@/plugins/form-validation.js";
import "@/plugins/fragment.js";
import "@/plugins/star-rating.js";
import "@/plugins/toggle-button.js";
import "@/plugins/toast-notification.js";
import "@/plugins/country-select.js";
import "@/plugins/rich-editor.js";
import { v4 as uuidv4 } from "uuid";

Vue.config.productionTip = false;

Vue.filter("toCurrency", function (value) {
  if (value === null || value == undefined) {
    return 0;
  }

  if (Object.keys(store.state.currency).length <= 0) {
    return 0;
  }

  var val = value / parseInt(store.state.currency.nilai, 10);
  var valStr = "";
  if (store.state.countryCode == "MY") {
    val = (Math.round(val * 100) / 100).toFixed(2);
    valStr = val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } else {
    valStr = val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }
  return store.state.currency.kode_domestik + " " + valStr;
});

Vue.filter("exchangeCurrency", function (value) {
  if (value === null || value == undefined) {
    return 0;
  }

  if (Object.keys(store.state.currency).length <= 0) {
    return 0;
  }
  var val = value * parseInt(store.state.currency.nilai, 10);
  return val;
});

Vue.filter("reformatHour", function (value) {
  var str = "";
  if (value != null && value != "") {
    var val_arr = value.split(":");
    str = val_arr[0] + ":" + val_arr[1];
  }
  return str;
});

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyDve3et3NQHHtsWjsWZm7oA1zdWaZBW4ko",
    libraries: "places",
  },
});

Vue.use(VueGeolocation);

Vue.prototype.$uuidv4 = uuidv4;

Vue.prototype.$apiTripwe = process.env.VUE_APP_TRIPWE_PUBLIC_API;
Vue.prototype.$apiUatTripwe = process.env.VUE_APP_UAT_TRIPWE_PUBLIC_API;
Vue.prototype.$apiSaebo = process.env.VUE_APP_SAEBO_PUBLIC_API;
Vue.prototype.$apiSaeboTW = process.env.VUE_APP_SAEBO_TW_PUBLIC_API;
Vue.prototype.$apiSaeboTWV2 = process.env.VUE_APP_SAEBO_TWV2_PUBLIC_API;
Vue.prototype.$apiSaeboTWUAT = process.env.VUE_APP_SAEBO_TW_UAT_PUBLIC_API;
Vue.prototype.$apiSaeboBNI = process.env.VUE_APP_SAEBO_BNI_PUBLIC_API;
Vue.prototype.$apiSaeboBNIShort = process.env.VUE_APP_SAEBOBNI_API;
Vue.prototype.$apiSaeboOTP = process.env.VUE_APP_SAEBO_OTP_API;
Vue.prototype.$apiTripweWeb = process.env.VUE_APP_TRIPWE_WEB;
Vue.prototype.$apiTripweWebV2 = process.env.VUE_APP_TRIPWE_WEB_V2;
// Vue.prototype.$apiTripweWebUat = process.env.VUE_APP_TRIPWE_WEB_UAT;
// Vue.prototype.$apiTripweWebUatV2 = process.env.VUE_APP_TRIPWE_WEB_UAT_V2;

Vue.config.devtools = true
Vue.config.debug = true
Vue.config.silent = true

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
