import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import store from '../store';
import router from '../router';

axios.interceptors.request.use(request => {
    if(request.url.indexOf('https://tripwe.id/ic/') !== -1 || request.url.indexOf('https://tripwe.id/apptripwe') !== -1){
        return request;
    }else{
        request.headers.common.Authorization = `Bearer ${store.state.token}`;
        return request;
    }
});
axios.interceptors.response.use(
    response => response,
    error => {
      const {status} = error.response;
      if (status == 401) {
        if(store.state.token != ''){
          Vue.toasted.global.error('Your device has ben logged out');
        }
        router.push({name: 'Login'});
        store.dispatch('logout');
      }
      return Promise.reject(error);
   }
  );

Vue.use(VueAxios, axios);
