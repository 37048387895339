import Vue from 'vue';
import Toasted from 'vue-toasted';

Vue.use(Toasted, {
    position: 'top-center',
    duration: 3000,
    singleton: true
});

Vue.toasted.register('success', message => message, { className: ['toast-notification-success'] });
Vue.toasted.register('error', message => message, { className: ['toast-notification-error'] });
Vue.toasted.register('warning', message => message, { className: ['toast-notification-warning'] });
