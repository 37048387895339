import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("../views/Home.vue"),
  },
  {
    path: "/about",
    name: "About",
    component: () => import("../views/About.vue"),
  },
  {
    path: "/career",
    name: "Career",
    component: () => import("../views/Career.vue"),
  },

  {
    path: "/agreement",
    name: "UserAgreement",
    component: () => import("../views/UserAgreement.vue"),
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    component: () => import("../views/PrivacyPolicy.vue"),
  },
  {
    path: "/our-partner",
    name: "OurPartner",
    component: () => import("../views/OurPartner.vue"),
  },
  {
    path: "/our-branch",
    name: "OurBranch",
    component: () => import("../views/OurBranch.vue"),
  },
  {
    path: "/contact",
    name: "CustomerSupport",
    component: () => import("../views/CustomerSupport.vue"),
  },
  {
    path: "/list-paket",
    name: "ListPaket",
    component: () => import("../views/ListPaket.vue"),
  },
  {
    path: "/list-paket-toko/:slug",
    name: "ListPaketToko",
    component: () => import("../views/ListPaketToko.vue"),
  },
  {
    path: "/rental",
    name: "Rental",
    component: () => import("../views/Rental.vue"),
  },
  {
    path: "/geolocation",
    name: "Geolocation",
    component: () => import("../views/GeolocationTest.vue"),
  },
  {
    path: "/auth",
    name: "Auth",
    redirect: { name: "Login" },
    component: () => import("@/views/Auth.vue"),
    children: [
      {
        path: "login",
        name: "Login",
        component: () => import("@/views/auth/Login.vue"),
      },
      {
        path: "register",
        name: "Register",
        component: () => import("@/views/auth/Register.vue"),
      },
      {
        path: "forgotpassword",
        name: "ForgotPassword",
        component: () => import("@/views/auth/ForgotPassword.vue"),
      },
    ],
  },
  {
    path: "/detailpaket/:slug/:trip?",
    name: "DetailPaket",
    component: () => import("@/views/DetailPaket.vue"),
    props: (route) => ({
      data: route.params.data,
      ...route.params,
    }),
  },
  {
    path: "/package-detail/:slug",
    name: "PackageDetail",
    component: () => import("@/views/PackageDetail.vue"),
  },
  {
    path: "/detailrental/:slug/:trip?",
    name: "DetailRental",
    component: () => import("../views/DetailRental.vue"),
    props: (route) => ({
      data: route.params.data,
      ...route.params,
    }),
  },
  {
    path: `/news`,
    name: "News",
    redirect: { name: "NewsIndex" },
    component: () => import("@/views/News.vue"),
    children: [
      {
        path: "",
        name: "NewsIndex",
        component: () => import("@/views/news/Index.vue"),
      },
      {
        path: ":slug",
        name: "DetailNews",
        component: () => import("@/views/news/DetailNews.vue"),
      },
    ],
  },
  {
    path: "/faq",
    name: "FAQ",
    redirect: { name: "FaqIndex" },
    component: () => import("../views/FAQ.vue"),
    children: [
      {
        path: "",
        name: "FaqIndex",
        component: () => import("../views/faq/Index.vue"),
      },
      {
        path: "user",
        name: "FaqUser",
        redirect: { name: "UserFAQIndex" },
        component: () => import("../views/faq/FaqUser.vue"),
        children: [
          {
            path: "",
            name: "UserFAQIndex",
            component: () => import("../views/faq/user/FAQUserIndex.vue"),
          },
          {
            path: "account/:id?",
            name: "AccountFAQ",
            component: () => import("../views/faq/user/Account.vue"),
          },
          {
            path: "general/:id?",
            name: "GeneralInfoFAQ",
            component: () => import("../views/faq/user/GeneralInfo.vue"),
          },
          {
            path: "trips/:id?/:question?",
            name: "TripsFAQ",
            component: () => import("../views/faq/user/Trips.vue"),
          },
          {
            path: "balance/:id?",
            name: "TripwepayFAQ",
            component: () => import("../views/faq/user/Tripwepay.vue"),
          },
        ],
      },
      {
        path: "merchant/:id?",
        name: "FaqMerchant",
        component: () => import("../views/faq/FaqMerchant.vue"),
      },
    ],
  },
  {
    path: "/destinations",
    name: "Destination",
    redirect: { name: "DestinationIndex" },
    component: () => import("@/views/Destination.vue"),
    children: [
      {
        path: "",
        name: "DestinationIndex",
        component: () => import("@/views/destination/Index.vue"),
      },
      {
        path: ":slug",
        name: "DestinationDetail",
        component: () => import("@/views/destination/Detail.vue"),
      },
    ],
  },
  {
    path: "/video",
    name: "Video",
    redirect: { name: "VideoIndex" },
    component: () => import("@/views/Video.vue"),
    children: [
      {
        path: "",
        name: "VideoIndex",
        component: () => import("@/views/video/Index.vue"),
      },
      {
        path: ":slug",
        name: "VideoDetail",
        component: () => import("@/views/video/Detail.vue"),
      },
    ],
  },
  {
    path: "/wishlist",
    name: "Wishlist",
    component: () => import("@/views/Wishlist.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/special-offers",
    name: "SpecialOffers",
    component: () => import("@/views/SpecialOffers.vue"),
  },
  {
    path: "/search-page/:keyword?",
    name: "SearchPage",
    component: () => import("@/views/SearchPage.vue"),
  },
  {
    path: "/checkoutpaket/:slug/:trip?",
    name: "CheckoutPaket",
    component: () => import("@/views/CheckoutPaket.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/checkoutrental/:slug/:trip?",
    name: "CheckoutRental",
    component: () => import("@/views/CheckoutRental.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/invoice/:slug/:trip",
    name: "Invoice",
    component: () => import("@/views/Invoice.vue"),
    meta: { requiresAuth: true },
    props: (route) => ({
      data: route.params.data,
      ...route.params,
    }),
  },
  {
    path: "/invoice-detail/:nobukti",
    name: "TransactionDetail",
    component: () => import("@/views/TransactionDetail.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/waiver",
    name: "Waiver",
    component: () => import("@/views/Waiver.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/invoicedownload",
    name: "InvoiceDownload",
    component: () => import("@/views/InvoiceDownload.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/admin",
    name: "Admin",
    component: () => import("@/views/Admin.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/notifications",
    name: "Notifications",
    component: () => import("@/views/Notifications.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/account",
    name: "Account",
    redirect: { name: "AccountProfile" },
    component: () => import("@/views/Account.vue"),
    meta: { requiresAuth: true },
    children: [
      {
        path: "settings",
        name: "AccountSetting",
        redirect: { name: "AccountProfile" },
        component: () => import("@/views/account/Setting.vue"),
        children: [
          {
            path: "profile",
            name: "AccountProfile",
            component: () => import("@/views/account/setting/Profile.vue"),
          },
          {
            path: "password",
            name: "AccountPassword",
            component: () => import("@/views/account/setting/Password.vue"),
          },
          {
            path: "pin",
            name: "AccountPin",
            component: () => import("@/views/account/setting/Pin.vue"),
          },
          {
            path: "address",
            name: "AccountAddress",
            redirect: { name: "AccountAddressIndex" },
            component: () => import("@/views/account/setting/Address.vue"),
            children: [
              {
                path: "",
                name: "AccountAddressIndex",
                component: () =>
                  import("@/views/account/setting/address/Index.vue"),
              },
              {
                path: "add",
                name: "AccountAddressAdd",
                component: () =>
                  import("@/views/account/setting/address/Add.vue"),
              },
              {
                path: "edit",
                name: "AccountAddressEdit",
                component: () =>
                  import("@/views/account/setting/address/Edit.vue"),
              },
            ],
          },
        ],
      },
      {
        path: "vouchers",
        name: "AccountVoucher",
        redirect: { name: "AccountVoucherIndex" },
        component: () => import("@/views/account/Voucher.vue"),
        children: [
          {
            path: "",
            name: "AccountVoucherIndex",
            component: () => import("@/views/account/voucher/Index.vue"),
          },
          {
            path: "detail",
            name: "AccountVoucherDetail",
            component: () => import("@/views/account/voucher/Detail.vue"),
          },
        ],
      },
      {
        path: "reviews",
        name: "AccountReview",
        redirect: { name: "AccountReviewIndex" },
        component: () => import("@/views/account/Review.vue"),
        children: [
          {
            path: "",
            name: "AccountReviewIndex",
            component: () => import("@/views/account/review/Index.vue"),
          },
          {
            path: "histories",
            name: "AccountReviewHistory",
            redirect: { name: "AccountReviewHistoryIndex" },
            component: () => import("@/views/account/review/History.vue"),
            children: [
              {
                path: "",
                name: "AccountReviewHistoryIndex",
                component: () =>
                  import("@/views/account/review/history/Index.vue"),
              },
              {
                path: "detail",
                name: "AccountReviewHistoryDetail",
                component: () =>
                  import("@/views/account/review/history/Detail.vue"),
              },
            ],
          },
        ],
      },
      {
        path: "crossselling",
        name: "CrossSelling",
        redirect: { name: "CrossSellingHold" },
        component: () => import("@/views/account/CrossSelling.vue"),
        children: [
          {
            path: "hold",
            name: "CrossSellingHold",
            component: () =>
              import("@/views/account/crossselling/CrossSellingHold.vue"),
          },
          {
            path: "release",
            name: "CrossSellingRelease",
            component: () =>
              import("@/views/account/crossselling/CrossSellingRelease.vue"),
          },
        ],
      },
      {
        path: "/article",
        name: "Article",
        redirect: { name: "BlogArticle" },
        component: () => import("@/views/account/Article.vue"),
        meta: { requiresAuth: true, isAdmin: true },
        children: [
          {
            path: "blog",
            name: "BlogArticle",
            component: () => import("@/views/account/article/BlogArticle.vue"),
            meta: { requiresAuth: true, isAdmin: true },
          },
          {
            path: "pressroom",
            name: "PressRoomArticle",
            component: () =>
              import("@/views/account/article/PressRoomArticle.vue"),
            meta: { requiresAuth: true, isAdmin: true },
          },
        ],
      },
    ],
  },
  {
    path: "/balance",
    name: "TripwePay",
    redirect: { name: "Transactions" },
    component: () => import("@/views/TripwePay"),
    meta: { requiresAuth: true },
    children: [
      // {
      //   path: "topup",
      //   name: "Topup",
      //   component: () => import("@/views/TripwePay/Topup.vue"),
      // },
      {
        path: "transfers",
        name: "Transfers",
        redirect: { name: "ToTripwePay" },
        component: () => import("@/views/TripwePay/Transfers/index.vue"),
        children: [
          {
            path: "to-balance",
            name: "ToTripwePay",
            redirect: { name: "ToTripwePayInput" },
            component: () =>
              import("@/views/TripwePay/Transfers/ToTripwePay/index.vue"),
            children: [
              {
                path: "",
                name: "ToTripwePayInput",
                component: () =>
                  import("@/views/TripwePay/Transfers/ToTripwePay/Input.vue"),
              },
              {
                path: ":user",
                name: "ToTripwePayFinal",
                component: () =>
                  import("@/views/TripwePay/Transfers/ToTripwePay/Final.vue"),
              },
            ],
          },
          {
            path: "to-bank",
            name: "ToBank",
            redirect: { name: "ToBankInput" },
            component: () =>
              import("@/views/TripwePay/Transfers/ToBank/index.vue"),
            children: [
              {
                path: "",
                name: "ToBankInput",
                component: () =>
                  import("@/views/TripwePay/Transfers/ToBank/Input.vue"),
              },
              {
                path: ":user",
                name: "ToBankFinal",
                component: () =>
                  import("@/views/TripwePay/Transfers/ToBank/Final.vue"),
              },
            ],
          },
        ],
      },
      {
        path: "transactions",
        name: "Transactions",
        component: () => import("@/views/TripwePay/Transactions.vue"),
      },
      {
        path: "settings",
        name: "Settings",
        redirect: { name: "ResetPin" },
        component: () => import("@/views/TripwePay/Settings.vue"),
        children: [
          {
            path: "reset-pin",
            name: "ResetPin",
            component: () => import("@/views/TripwePay/Setting/Reset.vue"),
          },
          {
            path: "forgot-pin",
            name: "ForgotPin",
            component: () => import("@/views/TripwePay/Setting/Forgot.vue"),
          },
          {
            path: "create-pin",
            name: "CreatePin",
            component: () => import("@/views/TripwePay/Setting/Create.vue"),
          },
        ],
      },
    ],
  },
  {
    path: "/dana",
    name: "Dana",
    redirect: { name: "DanaTransactionHistory" },
    component: () => import("@/views/dana"),
    meta: { requiresAuth: true },
    children: [
      {
        path: "topup",
        name: "DanaTopup",
        component: () => import("@/views/dana/Topup.vue"),
      },
      {
        path: "transaction-history",
        name: "DanaTransactionHistory",
        component: () => import("@/views/dana/Transactions.vue"),
      },
    ],
  },
  {
    path: "/topupexternal",
    name: "TopupExternal",
    redirect: { name: "TopupExternal" },
    component: () => import("@/views/TopupExternal"),
    meta: { requiresAuth: true },
    children: [
      {
        path: "to-tripwepay",
        name: "TopupToTripwePay",
        redirect: { name: "TopupToTripwePayInput" },
        component: () =>
          import("@/views/TopupExternal/Transfers/ToTripwePay/index.vue"),
        children: [
          {
            path: "",
            name: "TopupToTripwePayInput",
            component: () =>
              import("@/views/TopupExternal/Transfers/ToTripwePay/Input.vue"),
          },
          {
            path: ":user",
            name: "TopupToTripwePayFinal",
            component: () =>
              import("@/views/TopupExternal/Transfers/ToTripwePay/Final.vue"),
          },
        ],
      },
    ],
  },
  {
    path: "/pageframe",
    name: "PageFrame",
    component: () => import("@/views/PageFrame.vue"),
  },
  {
    path: "/blog",
    name: "Blog",
    redirect: { name: "BlogList" },
    component: () => import("@/views/Blog.vue"),
    children: [
      {
        path: "list",
        name: "BlogList",
        component: () => import("@/views/blog/BlogList.vue"),
      },
      {
        path: "detail",
        name: "BlogDetail",
        component: () => import("@/views/blog/BlogDetail.vue"),
      },
    ],
  },
  {
    path: "/press-room",
    name: "PressRoom",
    redirect: { name: "PressRoomList" },
    component: () => import("@/views/PressRoom.vue"),
    children: [
      {
        path: "list",
        name: "PressRoomList",
        component: () => import("@/views/PressRoom/PressRoomList.vue"),
      },
      {
        path: "detail",
        name: "PressRoomDetail",
        component: () => import("@/views/PressRoom/PressRoomDetail.vue"),
      },
    ],
  },
  {
    path: "/events",
    name: "Events",
    redirect: { name: "EventList" },
    component: () => import("@/views/Events.vue"),
    children: [
      {
        path: "list",
        name: "EventList",
        component: () => import("@/views/events/EventList.vue"),
      },
      {
        path: "detail/:id",
        name: "EventDetail",
        component: () => import("@/views/events/EventDetail.vue"),
      },
      {
        path: "registration",
        name: "EventRegistration",
        meta: { requiresAuth: true },
        component: () => import("@/views/events/EventRegistration.vue"),
      },
      {
        path: "ticket",
        name: "EventTicket",
        meta: { requiresAuth: true },
        component: () => import("@/views/events/EventTicket.vue"),
      },
    ],
  },
  {
    path: "/insurance",
    name: "Insurance",
    redirect: { name: "InsuranceList" },
    component: () => import("@/views/Insurance.vue"),
    children: [
      {
        path: "list",
        name: "InsuranceList",
        component: () => import("@/views/insurance/InsuranceList.vue"),
      },
      {
        path: "Detail",
        name: "InsuranceDetail",
        component: () => import("@/views/insurance/InsuranceDetail.vue"),
      },
      {
        path: "Payment",
        name: "InsurancePayment",
        component: () => import("@/views/insurance/InsurancePayment.vue"),
      },
    ],
  },
  {
    path: "/waiver-admin",
    name: "WaiverAdmin",
    redirect: { name: "WaiverAdminList" },
    component: () => import("@/views/WaiverAdmin.vue"),
    meta: { requiresAuth: true, isAdminToko: true },
    children: [
      {
        path: "list",
        name: "WaiverAdminList",
        component: () => import("@/views/WaiverAdmin/WaiverAdminList.vue"),
        meta: { requiresAuth: true, isAdminToko: true },
      },
      {
        path: "form",
        name: "WaiverAdminForm",
        component: () => import("@/views/WaiverAdmin/WaiverAdminForm.vue"),
        meta: { requiresAuth: true, isAdminToko: true },
      },
    ],
  },
  {
    path: "/404",
    alias: "*",
    name: "Custom404",
    component: () => import("@/views/404.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0, behavior: "smooth" };
  },
});

router.beforeEach(async (to, from, next) => {
  store.commit("setRouteName", from.name);
  store.commit("setRouteParam", from.params)

  if (store.state.appLoading) {
    await store.dispatch("authenticateUser");
  }

  if (to.matched[0].name == "Auth" && store.getters.isLoggedIn) {
    next({ name: "Home" });
  }

  if (to.matched[0].name == "Dana" && (!store.getters.isLoggedIn || !store.getters.hasConnectDana)) {
    next({ name: "Home" });
  }

  if (to.matched.some((route) => route.meta.requiresAuth)) {
    if (store.getters.isLoggedIn) {
      Vue.$http
        .get(`${process.env.VUE_APP_TRIPWE_WEB}/tripwepay/balance`)
        .then((res) => {
          store.commit("setSaldo", res.data.response);
        });
      Vue.$http
        .get(`${process.env.VUE_APP_TRIPWE_WEB}/dana/accounts/balance`)
        .then((res) => {
          if(res.data.status == 200){
            store.commit("setSaldoDana", res.data.data);
            store.commit("setConnectDana", true);
          }else{
            store.commit("setConnectDana", false);
          }
        });
      Vue.$http
        .get(`${process.env.VUE_APP_TRIPWE_WEB}/voucher/count`)
        .then((res) => {
          store.commit("setVoucherCount", res.data.data.total);
        });

      if (to.meta.isAdmin && store.state.user.admin == "1") {
        next();
      } else if (to.meta.isAdminToko && (store.state.user.admin == "1" || store.state.user.status_merchant == "1" || store.state.user.id_user == "4" )) {
        next();
      } else if (!to.meta.isAdminToko && !to.meta.isAdmin) {
        next();
      } else {
        next({ name: "Home" });
      }
    } else {
      next({ name: "Login" });
    }
  } else {
    if (store.getters.isLoggedIn) {
      Vue.$http
        .get(`${process.env.VUE_APP_TRIPWE_WEB}/tripwepay/balance`)
        .then((res) => {
          store.commit("setSaldo", res.data.response);
        });
      Vue.$http
      .get(`${process.env.VUE_APP_TRIPWE_WEB}/dana/accounts/balance`)
      .then((res) => {
        if(res.data.status == 200){
          store.commit("setSaldoDana", res.data.data);
          store.commit("setConnectDana", true);
        }else{
          store.commit("setConnectDana", false);
        }
      });
    }
    next();
  }
});

const originalPush = router.push;

router.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject);
  }

  return originalPush.call(this, location).catch((err) => {
    if (VueRouter.isNavigationFailure(err)) {
      return err;
    }

    return Promise.reject(err);
  });
};

export default router;
