import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import SecureLS from 'secure-ls';

const ls = new SecureLS({
    isCompression: false,
    encodingType: 'aes',
    encryptionSecret: 'XB3etb52tshlodMDylvOeVvu4NqgpEZg'
});

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        appLoading: true,
        overlayLoading: false,
        user: {},
        token: "",
        saldo: {},
        saldoDana: {},
        connectDana: false,
        voucherCount: 0,
        notifications: [],
        idWilayah: null,
        package: {},
        packageList: [],
        bookRental: {},
        detailInv: {},
        waiverPackage: {},
        currency: {
            kode_domestik: "Rp.",
            nilai: 1
        },
        breadcrumbItems: [],
        countryCode: "ID",
        routeName: "",
        routeParam: "",
        currentLocation: {},
        showPopupHome: true,
    },
    getters: {
        isLoggedIn(state) {
            return Object.keys(state.user).length > 0;
        },
        hasConnectDana(state) {
            return state.connectDana;
        },
        latestNotifications(state) {
            return [...state.notifications].slice(0, 3);
        },
        hasUnreadNotifications(state) {
            return state.notifications.filter(val => val.read == 0).length > 0;
        },
        tokenLogin(state){
            return state.token;
        }
    },
    mutations: {
        setAppLoading(state, payload) {
            state.appLoading = payload;
        },
        setOverlayLoading(state, payload) {
            state.overlayLoading = payload;
        },
        setUser(state, payload) {
            state.user = payload;
        },
        setToken(state, payload) {
            state.token = payload;
        },
        setSaldo(state, payload) {
            state.saldo = payload;
        },
        setSaldoDana(state, payload) {
            state.saldoDana = payload;
        },
        setConnectDana(state, payload) {
            state.connectDana = payload;
        },
        setVoucherCount(state, payload) {
            state.voucherCount = payload;
        },
        setNotifications(state, payload) {
            state.notifications = payload;
        },
        setIdWilayah(state, payload) {
            state.idWilayah = payload;
        },
        setPackage(state, payload) {
            state.package = payload;
        },
        setPackageList(state, payload) {
            state.packageList = payload;
        },
        setBookRental(state, payload) {
            state.bookRental = payload;
        },
        setWaiverPackage(state, payload) {
            state.waiverPackage = payload;
        },
        setDetailInv(state, payload) {
            state.detailInv = payload;
        },
        setBreadcrumbItems(state, payload) {
            state.breadcrumbItems = payload;
        },
        setCountryCode(state, payload) {
            state.countryCode = payload;
        },
        setCurrency(state, payload) {
            state.currency = payload;
        },
        setRouteName(state, payload) {
            state.routeName = payload;
        },
        setRouteParam(state, payload) {
            state.routeParam = payload;
        },
        setCurrentLocation(state, payload) {
            state.currentLocation = payload;
        },
        setShowPopupHome(state, payload) {
            state.showPopupHome = payload;
        }
    },
    actions: {
        login({ commit }, payload) {
            commit('setUser', payload.user);
            commit('setToken', payload.token);
            // commit('setSaldo', payload.saldo);
            // commit('setVoucherCount', payload.voucherCount);
            // commit('setNotifications', payload.notifications);
            commit('setCountryCode', "");
            commit('setShowPopupHome', true);
        },
        logout({ commit }) {
            commit('setUser', {});
            commit('setToken', "");
            commit('setSaldo', {});
            commit('setSaldoDana', {});
            commit('setConnectDana', false);
            commit('setVoucherCount', 0);
            commit('setNotifications', []);
            commit('setPackage', {});
            commit('setIdWilayah', null);
        },
        async authenticateUser({ state, getters, commit, dispatch }) {
            if (getters.isLoggedIn) {
                // const data = new FormData();
                // data.append('txtUser', state.user.telepon);
                // data.append('txtPass', state.user.password);

                // try {
                //     // const user = await Vue.$http.post(
                //     //     `${process.env.VUE_APP_TRIPWE_PUBLIC_API}/ic/jetski-android-new/user_login_dv16.php`,
                //     //     data
                //     // );

                    
                //     const user = await this.$http.post(
                //         `${this.$apiTripweWeb}/login`, {
                //             "txtUser" : state.user.telepon,
                //             "txtPass" : state.user.password
                //         }
                //     );

                //     if (user.data.result[0].id_user == null) {
                //         throw 'Invalid credentials';
                //     }

                //     // if (state.user.password != user.data.result[0].password) {
                //     //     throw 'Invalid credentials';
                //     // }

                //     const response = await Promise.all([
                //         Vue.$http.post(`${process.env.VUE_APP_SAEBO_TWV2_PUBLIC_API}/balanceTWV2`, {
                //             virtual_account: user.data.user.va
                //         }),
                //         Vue.$http.post(`${process.env.VUE_APP_SAEBO_PUBLIC_API}/ListOrderV3`, {
                //             id: 'UATVCREDEEMCOUNT',
                //             kodecustomer: user.data.user.id_user
                //         }),
                //         Vue.$http.get(
                //             `${process.env.VUE_APP_TRIPWE_PUBLIC_API}/ic/jetski-android-new/list_notifikasi_saya.php`,
                //             {
                //                 params: { id: user.data.user.id_user }
                //             }
                //         )
                //     ]);

                //     if (response[0].data.status != 200 || response[1].data.length == 0) {
                //         throw 'Login failed';
                //     }

                //     user.data.result[0].password = state.user.password;

                //     dispatch('login', {
                //         user: user.data.user,
                //         token: user.data.token,
                //         saldo: response[0].data.response,
                //         voucherCount: response[1].data[0].CONT,
                //         notifications: response[2].data ?? []
                //     });
                // } catch (error) {
                //     dispatch('logout');
                // }

                commit('setAppLoading', false);
            } else {
                dispatch('logout');
                commit('setAppLoading', false);
            }
        }
    },
    plugins: [
        createPersistedState({
            paths: ['user', 'token', 'saldo', 'voucherCount', 'package', 'packageList', 'bookRental', 'idWilayah', 'detailInv', 
            'waiverPackage', 'countryCode', 'currency', 'routeName', 'routeParam', 'currentLocation', 'showPopupHome', 'saldoDana', 'connectDana'],
            storage: {
                getItem: key => ls.get(key),
                setItem: (key, value) => ls.set(key, value),
                removeItem: key => ls.remove(key)
            }
        })
    ]
});
